import {
  List,
  Datagrid,
  TextField,
  Button,
  DeleteButton,
  useDataProvider,
  useNotify,
  TextInput,
  SelectInput,
  Filter,
  FunctionField,
  useRefresh,
} from "react-admin";
import { useCallback, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

// Фильтры с поиском по автору и статусу
const CommentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск по автору" source="commentator_name" alwaysOn />
    <SelectInput
      label="Статус"
      source="status"
      choices={[
        { id: "approved", name: "Одобрен" },
        { id: "hidden", name: "Скрыт" },
      ]}
      alwaysOn
    />
  </Filter>
);

// Кнопка одобрить/скрыть комментарий
const CommentStatusButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const queryClient = useQueryClient(); // Подключаем queryClient

  const updateStatus = useCallback(
    async (newStatus) => {
      if (!record || !record.id) return;

      try {
        await dataProvider.update("comments", {
          id: record.id,
          data: { status: newStatus },
        });

        notify(
          newStatus === "approved"
            ? "Комментарий одобрен"
            : "Комментарий скрыт",
          { type: "success" }
        );

        queryClient.invalidateQueries(["comments"]); // Принудительно сбрасываем кэш для комментариев
      } catch (error) {
        notify("Ошибка при обновлении комментария", { type: "warning" });
      }
    },
    [record, dataProvider, notify, queryClient]
  );

  if (!record || !record.id) return null;

  return record.status === "hidden" ? (
    <Button label="Одобрить" onClick={() => updateStatus("approved")} />
  ) : (
    <Button label="Скрыть" onClick={() => updateStatus("hidden")} />
  );
};

// Кнопка заблокировать/разблокировать автора
const BlockUnblockButton = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  if (!record || typeof record.blocked === "undefined") {
    return null;
  }

  const toggleBlockStatus = async (newStatus) => {
    try {
      await dataProvider.update("commentators", {
        id: record.commentator_id,
        data: { blocked: newStatus ? 1 : 0 },
      });

      notify(newStatus ? "Автор заблокирован" : "Автор разблокирован", {
        type: "success",
      });

      refresh();
    } catch (error) {
      console.error("Ошибка при блокировке/разблокировке автора:", error);
      notify("Ошибка при обновлении статуса автора", { type: "warning" });
    }
  };

  return record.blocked === 1 ? (
    <Button label="Разблокировать" onClick={() => toggleBlockStatus(false)} />
  ) : (
    <Button label="Заблокировать" onClick={() => toggleBlockStatus(true)} />
  );
};

// Основной компонент с таблицей комментариев
export const CommentsList = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dataProvider.getList("comments", {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "created_at", order: "DESC" },
        });
        refresh(); // Принудительно обновляем данные после получения
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchData();
  }, [refresh]);

  return (
    <List
      filters={<CommentFilter />}
      queryOptions={{ cacheTime: 0, refetchOnWindowFocus: true }} // Принудительное обновление данных
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="id" />
        <TextField source="commentator_name" label="Автор" />
        <TextField source="text" label="Комментарий" />
        <TextField source="likes" label="Лайки" />
        <FunctionField
          label="Статус"
          render={(record) => <CommentStatusButton record={record} />}
        />
        <FunctionField
          label="Действие с автором"
          render={(record) => <BlockUnblockButton record={record} />}
        />
        <DeleteButton {...props} mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};
