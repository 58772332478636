import React from "react";
import { Admin, Resource, localStorageStore } from "react-admin";
import { QueryClient } from "@tanstack/react-query";

import UserIcon from "@material-ui/icons/Group";
import ProductIcon from "@material-ui/icons/Store";
import Comment from "@material-ui/icons/Comment";

import ProductsList from "./components/ProductsList";
import ProductEdit from "./components/ProductEdit";
import ProductCreate from "./components/ProductCreate";

import UserCreate from "./components/UserCreate";
import UsersList from "./components/UsersList";

import CommentsList from "./components/CommentsList";

import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";

import AdminDashboard from "./components/AdminDashboard";

// import AdminUserList from "./components/AdminUserList";
// import AdminUserCreate from "./components/AdminUserCreate";
// import AdminUserEdit from "./components/AdminUserEdit";
// import AdminUserShow from "./components/AdminUserShow";

import CommentatorList from "./components/CommentatorList/CommentatorList";
import CommentatorEdit from "./components/CommentatorEdit/CommentatorEdit";

const store = localStorageStore(undefined, "ECommerce");

// Основное приложени
const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        structuralSharing: false,
      },
      mutations: {
        retryDelay: 10000,
      },
      cacheTime: 0,
    },
  });

  return (
    <Admin
      store={store}
      dataProvider={dataProvider}
      authProvider={authProvider}
      queryClient={queryClient}
      dashboard={AdminDashboard}
    >
      <Resource
        name="products"
        list={ProductsList}
        edit={ProductEdit}
        create={ProductCreate}
        icon={ProductIcon}
      />
      <Resource name="comments" list={CommentsList} icon={Comment} />
      <Resource
        name="users"
        list={UsersList}
        create={UserCreate}
        icon={UserIcon}
      />

      {/* <Resource
        name="commentators"
        list={CommentatorList}
        edit={CommentatorEdit}
      /> */}

      {/* <Resource
        name="admin_users"
        list={AdminUserList}
        create={AdminUserCreate}
        edit={AdminUserEdit}
        show={AdminUserShow}
      /> */}
    </Admin>
  );
};

export default App;
