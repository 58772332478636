import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  useNotify,
  useRedirect,
  required,
  ImageInput,
  ImageField,
} from "react-admin";
import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
  DefaultEditorOptions,
} from "ra-input-rich-text";

export const ProductCreate = (props) => {
  const [files, setFiles] = useState([]);
  const notify = useNotify();
  const redirect = useRedirect();

  // Обработка изменения файлов
  const handleImageChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]); // Добавляем новые файлы к уже выбранным
  };

  // Удаление файла по индексу
  const handleDeleteImage = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleSubmit = (data) => {
    const formData = new FormData();

    // Добавляем обычные поля
    formData.append("name", data.name);
    formData.append("long_name", data.long_name);
    formData.append("description", data.description);
    formData.append("rating", data.rating || "");
    formData.append("old_price", data.old_price || "");
    formData.append("new_price", data.new_price);
    formData.append("availability", data.availability);
    formData.append("seller", data.seller);
    formData.append("visible", data.visible);
    formData.append("sort_order", data.sort_order);

    // Добавляем изображения
    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }

    // Отправка данных на сервер
    fetch(`${process.env.REACT_APP_API_URL}/products`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData, // Отправляем FormData
    })
      .then((response) => {
        if (response.ok) {
          notify("Товар успешно создан", { type: "success" });
          redirect("list", "products");
        } else {
          throw new Error("Ошибка при создании товара");
        }
      })
      .catch((error) => {
        notify("Ошибка при создании товара", { type: "error" });
        console.error("Ошибка:", error);
      });
  };

  return (
    <Create {...props}>
      <SimpleForm onSubmit={handleSubmit}>
        <TextInput source="name" label="Название" validate={[required()]} />

        <TextInput
          source="long_name"
          label="Длинное название"
          validate={[required()]}
        />

        <RichTextInput
          source="description"
          label="Описание"
          validate={[required()]}
          fullWidth={true}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect size={"large"} />
              <FormatButtons size={"large"} />
              <ListButtons size={"large"} />
              <LinkButtons size={"large"} />
              <QuoteButtons size={"large"} />
              <ClearButtons size={"large"} />
            </RichTextInputToolbar>
          }
        />

        <NumberInput source="rating" label="Рейтинг" defaultValue={0} />

        <NumberInput source="old_price" label="Старая цена" />
        <NumberInput
          source="new_price"
          label="Новая цена"
          validate={[required()]}
        />

        <SelectInput
          source="availability"
          choices={[
            { id: "На складе", name: "На складе" },
            { id: "Под заказ", name: "Под заказ" },
          ]}
          defaultValue="Под заказ"
          label="Доступность"
          validate={[required()]}
        />

        <TextInput source="seller" label="Продавец" validate={[required()]} />

        {/* Поле для выбора изображений */}
        <input
          type="file"
          onChange={handleImageChange}
          multiple
          accept="image/*"
          disabled={files.length === 2}
        />

        {/* Превью выбранных изображений с кнопкой для удаления */}
        {files && (
          <div
            style={{
              display: "flex",
              marginBottom: 40,
              flexDirection: "row",
              gap: 20,
              width: "100%",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {files.map((file, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  border: "1px solid #808080",
                  borderRadius: 10,
                  padding: 10,
                }}
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "10px",
                    objectFit: "cover",
                  }}
                />
                <button
                  type="button"
                  onClick={() => handleDeleteImage(index)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    padding: "5px",
                  }}
                >
                  Удалить
                </button>
              </div>
            ))}
          </div>
        )}
      </SimpleForm>
    </Create>
  );
};
