import { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EditButton,
  DeleteButton,
  FunctionField,
  TextInput,
  SelectInput,
  Filter,
  useDataProvider,
  useRecordContext,
} from "react-admin";

// Фильтры для поиска и статуса
const ProductFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Поиск по названию" source="name" alwaysOn />
    <TextInput label="Поиск по продавцу" source="seller" />
    <SelectInput
      label="Наличие"
      source="availability"
      choices={[
        { id: "in_stock", name: "В наличии" },
        { id: "out_of_stock", name: "Нет в наличии" },
      ]}
    />
  </Filter>
);

// Компонент для отображения изображения
const ImageFieldWithHover = ({}) => {
  const record = useRecordContext();

  if (!record || !record.image_url) {
    return <span>No image</span>;
  }

  const imageUrl = `${process.env.REACT_APP_API_URL}/uploads/${record.image_url}`;

  return (
    <div className="image-wrapper">
      <img
        src={imageUrl}
        alt={record.name}
        className="image-preview"
        width={24}
        height={24}
      />
    </div>
  );
};

export const ProductsList = (props) => {
  const [visibleColumns, setVisibleColumns] = useState([
    "id",
    "sort_order",
    "image_url",
    "name",
    // "long_name",
    // "description",
    "rating",
    "old_price",
    "new_price",
    "availability",
    "seller",
    "visible",
    "created_at",
    // "likes_count",
    // "comments_count",
    // "bookmarks_count",
  ]);

  // Функция для переключения видимости столбцов
  const toggleColumn = (column) => {
    setVisibleColumns((prevColumns) =>
      prevColumns.includes(column)
        ? prevColumns.filter((col) => col !== column)
        : [...prevColumns, column]
    );
  };

  return (
    <>
      <h4>Управление столбцами:</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          alignItems: "center",
          maxWidth: "75%",
          flexWrap: "wrap",
        }}
      >
        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("id")}
            onChange={() => toggleColumn("id")}
          />
          <span className="label-title">ID</span>
        </label>
        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("sort_order")}
            onChange={() => toggleColumn("sort_order")}
          />
          <span className="label-title">Сортировка</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("image_url")}
            onChange={() => toggleColumn("image_url")}
          />
          <span className="label-title">Фото</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("name")}
            onChange={() => toggleColumn("name")}
          />
          <span className="label-title">Название</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("long_name")}
            onChange={() => toggleColumn("long_name")}
          />
          <span className="label-title">Длинное название</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("description")}
            onChange={() => toggleColumn("description")}
          />
          <span className="label-title">Описание</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("rating")}
            onChange={() => toggleColumn("rating")}
          />
          <span className="label-title">Рейтинг</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("old_price")}
            onChange={() => toggleColumn("old_price")}
          />
          <span className="label-title">Старая цена</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("new_price")}
            onChange={() => toggleColumn("new_price")}
          />
          <span className="label-title">Новая цена</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("availability")}
            onChange={() => toggleColumn("availability")}
          />
          <span className="label-title">Наличие</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("visible")}
            onChange={() => toggleColumn("visible")}
          />
          <span className="label-title">Видимость</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("seller")}
            onChange={() => toggleColumn("seller")}
          />
          <span className="label-title">Продавец</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("created_at")}
            onChange={() => toggleColumn("created_at")}
          />
          <span className="label-title">Дата добавления</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("likes_count")}
            onChange={() => toggleColumn("likes_count")}
          />
          <span className="label-title">Likes</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("comments_count")}
            onChange={() => toggleColumn("comments_count")}
          />
          <span className="label-title">Комментариев</span>
        </label>

        <label className="label">
          <input
            type="checkbox"
            checked={visibleColumns.includes("bookmarks_count")}
            onChange={() => toggleColumn("bookmarks_count")}
          />
          <span className="label-title">В закладках</span>
        </label>
      </div>

      <List filters={<ProductFilter />} {...props}>
        <Datagrid>
          {visibleColumns.includes("id") && <TextField source="id" />}
          {visibleColumns.includes("sort_order") && (
            <NumberField source="sort_order" label="Сортировка" />
          )}

          {visibleColumns.includes("image_url") && (
            <FunctionField
              label="Фото"
              render={() => <ImageFieldWithHover />}
            />
          )}

          {visibleColumns.includes("name") && (
            <TextField source="name" label="Название" />
          )}

          {visibleColumns.includes("long_name") && (
            <TextField source="long_name" label="Длинное название" />
          )}

          {visibleColumns.includes("description") && (
            <NumberField source="description" label="Описание" />
          )}

          {visibleColumns.includes("rating") && (
            <NumberField source="rating" label="Рейтинг" />
          )}

          {visibleColumns.includes("old_price") && (
            <NumberField source="old_price" label="Старая цена" />
          )}

          {visibleColumns.includes("new_price") && (
            <NumberField source="new_price" label="Новая цена" />
          )}

          {visibleColumns.includes("availability") && (
            <TextField source="availability" label="Наличие" />
          )}

          {visibleColumns.includes("seller") && (
            <TextField source="seller" label="Продавец" />
          )}

          {visibleColumns.includes("visible") && (
            <TextField source="visible" label="Видимость" />
          )}

          {visibleColumns.includes("created_at") && (
            <TextField source="created_at" label="Дата добавления" />
          )}

          {visibleColumns.includes("likes_count") && (
            <TextField source="likes_count" label="Likes" />
          )}

          {visibleColumns.includes("comments_count") && (
            <TextField source="comments_count" label="Комментариев" />
          )}

          {visibleColumns.includes("likes_count") && (
            <TextField source="likes_count" label="Likes" />
          )}

          {visibleColumns.includes("bookmarks_count") && (
            <TextField source="bookmarks_count" label="В закладках" />
          )}
          <EditButton />
          <DeleteButton mutationMode="pessimistic" />
        </Datagrid>
      </List>
    </>
  );
};

// CSS для стилизации
const styles = `
  .image-wrapper {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .image-wrapper:hover {
    position: absolute;
    width: 200px;
    height: 200px;
    overflow: hidden;
    z-index: 10;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    gap: 5px
  }

  .label-title {
    flex-wrap: wrap; 
  }
`;

const styleTag = document.createElement("style");
styleTag.innerHTML = styles;
document.head.appendChild(styleTag);
