import { List, Datagrid, TextField } from "react-admin";

export const UsersList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" label="Имя пользователя" />
      <TextField source="role" label="Роль" />
    </Datagrid>
  </List>
);
