import { Create, SimpleForm, TextInput, SelectInput } from "react-admin";

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" label="Имя пользователя" />
      <TextInput source="password" label="Пароль" type="password" />
      <SelectInput
        source="role"
        label="Роль"
        choices={[
          { id: "admin", name: "Администратор" },
          { id: "editor", name: "Редактор" },
        ]}
      />
    </SimpleForm>
  </Create>
);
