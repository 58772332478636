import { fetchUtils } from "react-admin";
import { stringify } from "query-string";

const apiUrl = `${process.env.REACT_APP_API_URL}`; // "http://localhost:3010"; // API URL

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    try {
      const { headers, json } = await httpClient(url);

      if (!headers.has("x-total-count")) {
        throw new Error(
          "The X-Total-Count header is missing in the HTTP Response."
        );
      }

      console.log("API response:", json);

      return {
        data: json,
        total: parseInt(headers.get("x-total-count"), 10),
      };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // ????? ?????????? ??? ?????????? ?????? ??????
    }
  },
  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    // console.log("Fetching product with ID:", params.id);
    return await httpClient(url).then(({ json }) => {
      // console.log("Received data:", json);
      return {
        data: json,
      };
    });
  },

  create: async (resource, params) => {
    const formData = new FormData();

    for (const key in params.data) {
      if (params.data.hasOwnProperty(key) && key !== "images") {
        formData.append(key, params.data[key]);
      }
    }

    if (params.data.images && params.data.images.length) {
      params.data.images.forEach((file) => {
        formData.append("images", file.rawFile);
      });
    }

    return await fetch(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error while creating product");
        }
        return response.json();
      })
      .then((json) => ({
        data: { ...params.data, id: json.id },
      }));
  },

  update: async (resource, params) => {
    const body = JSON.stringify(params.data);

    return await fetch(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: body,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json", // ????????????? ?????????? Content-Type
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error while updating resource");
        }
        return response.json();
      })
      .then((json) => ({
        data: { ...params.data, id: json.id },
      }));
  },

  delete: async (resource, params) =>
    await httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(({ json }) => ({ data: json })),
};

export default dataProvider;
